import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useTranslation  } from 'react-i18next';
import OpenSourceDemoData from '../json/open-source-project-list.json';

const OpenSourceDetailContentSection = (props) => {
    const { t } = useTranslation();
    const [state] = useState({
        testSourceData: OpenSourceDemoData.data
    });

    const getProjectDetail = () => {
        let detail = state.testSourceData.filter(o => o.projectId === parseInt(props.projectId));
        
        if (detail.length > 0) {
            return detail[0];
        }
        return {};
    }

    const getLng = () => {
      let currentLng = JSON.parse(localStorage.getItem('i18nLng'));
      return currentLng === null ? 'US' : currentLng;
    }

    const getDescriptionByLng = () => {
        return getLng() === 'US' ? getProjectDetail().description.en : getLng() === 'DE' ? getProjectDetail().description.de : getProjectDetail().description.pt;
    }

    return (
        <React.Fragment>
            <section className="section">
                <div className="container">
                    <Row>
                        <Col lg="12">
                            <div className="mb-2">
                                <h3 className="big-text font-weight-bold text-uppercase open-source-detail-heading-title">
                                    {t('i18n_about')}
                                </h3>
                                <span className="author-span">
                                    {t('i18n_author')}: {getProjectDetail().author.name} <img className="author-avatar" src={getProjectDetail().author.photo} alt='' />
                                </span>
                                <a className="github-link" href={getProjectDetail().github} target="_blank" rel="noopener noreferrer">
                                    <img src="/images/github_icon.png" alt='' />
                                </a>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col lg="12">
                            <div className="open-source-description-html" dangerouslySetInnerHTML={{ __html: getDescriptionByLng() }}>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
        </React.Fragment>
    );
}
export default OpenSourceDetailContentSection;