import React from 'react';
import { Row, Col } from 'reactstrap';
import { Translation  } from 'react-i18next';

class AiStudioDescSection extends React.Component {
    render() {
        return (
            <React.Fragment>
                <section className="section">
                    <div className="container">
                        <Row>
                            <Col lg="12" md="12">
                                <p className="products-desc-text mb-3">
                                    <Translation>{ (t, { i18n }) => <>{t('i18n_ai_studio_contents1')}</> }</Translation>
                                </p>
                                <p className="products-desc-text mb-3">
                                    <Translation>{ (t, { i18n }) => <>{t('i18n_ai_studio_contents2')}</> }</Translation>
                                </p>
                                <p className="products-desc-text mb-3">
                                    <Translation>{ (t, { i18n }) => <>{t('i18n_ai_studio_contents3')}</> }</Translation>
                                </p>
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default AiStudioDescSection;