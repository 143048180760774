import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Translation  } from 'react-i18next';

class ServiceSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ServiceList: [{
                "icon": "/images/home-service-1.png",
                "title": "i18n_home_data_pipeline",
                "content": "i18n_home_data_pipeline_intro",
            }, {
                "icon": "/images/home-service-2.png",
                "title": "i18n_home_data_lakes",
                "content": "i18n_home_data_lakes_intro",
            }, {
                "icon": "/images/home-service-3.png",
                "title": "i18n_home_data_analytics",
                "content": "i18n_home_data_analytics_intro",
            }
            ],
            ServiceExtra: {
                "icon": "/images/home-service-4.png",
                "title": "i18n_home_data_engineering",
                "content1": [
                    "i18n_home_data_engineering_intro1",
                    "i18n_home_data_engineering_intro2", 
                    "i18n_home_data_engineering_intro3",
                    "i18n_home_data_engineering_intro4",
                    "i18n_home_data_engineering_intro5",
                    "i18n_home_data_engineering_intro6"
                ], 
                "content2": [
                    "i18n_home_data_engineering_intro7",
                    "i18n_home_data_engineering_intro8",
                    "i18n_home_data_engineering_intro9",
                    "i18n_home_data_engineering_intro10",
                    "i18n_home_data_engineering_intro11"
                ]
            }
        };
    }

    render() {
        return (
            <React.Fragment>
                <section className="section" id="services">
                    <div className="container">
                        <Row>
                            <Col lg="12">
                                <div className="mb-2">
                                    <h3 className="big-text mb-1 font-weight-bold text-uppercase">
                                        <Translation>
                                        {
                                            (t, { i18n }) => <>{t('i18n_home_our_services')}</>
                                        }
                                        </Translation>
                                    </h3>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            {this.state.ServiceList.map((service, index) => {
                                return <Col lg="4" md="6" key={index}>
                                    <div className="service-box rounded mt-4 p-4">
                                        <div className="service-icon mb-3">
                                            <img src={service.icon} alt=''/>
                                        </div>
                                        <div className="services-desc">
                                            <div className="service-title mb-1 position-relative">
                                                <h5 className="font-weight-normal">
                                                    <Link to="#" className="text-dark">
                                                        <Translation>{ (t, { i18n }) => <>{t(service.title)}</> }</Translation>
                                                    </Link>
                                                </h5>
                                            </div>
                                            <p className="service-intro mb-3">
                                                <Translation>{ (t, { i18n }) => <>{t(service.content)}</> }</Translation>
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            })}
                        </Row>
                        <Row>
                            <Col lg="12" md="12" key={4}>
                                <div className="service-box rounded mt-4 p-4">
                                    <img className="service-grey-atstract" src="/images/service-grey-atstract.png" alt='' />
                                    <div className="service-icon mb-3">
                                        <img src={this.state.ServiceExtra.icon} alt='' />
                                    </div>
                                    <div className="services-desc">
                                        <div className="service-title mb-1 position-relative">
                                            <h5 className="font-weight-normal"><Link to="#" className="text-dark"><Translation>{ (t, { i18n }) => <>{t(this.state.ServiceExtra.title)}</> }</Translation></Link></h5>
                                            <Row>
                                                <Col lg="4" md="6" key={55}>
                                                    { this.state.ServiceExtra.content1.map((exService, index) => <p key={index} className="service-intro mb-0">{<Translation>{ (t, { i18n }) => <>{t(exService)}</> }</Translation>}</p>) }
                                                </Col>
                                                <Col className="nd-service-intro" lg="4" md="6" key={5}>
                                                    { this.state.ServiceExtra.content2.map((exService, index) => <p key={index} className="service-intro mb-0">{<Translation>{ (t, { i18n }) => <>{t(exService)}</> }</Translation>}</p>) }
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default ServiceSection;