import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Translation   } from 'react-i18next';
import LanguageSelector from './LanguageSelector';

const Navbar = () => {

    return (
        <React.Fragment>
            <nav id="main_navbar" className="navbar navbar-expand-lg  fixed-top navbar-custom sticky sticky-dark">
                <div className="container">
                    <Link className="navbar-brand logo" to="/">
                        <img src="/images/dataseed-white-logo.png" height="30" alt='' />
                    </Link>
                    <div className="toggle-lang">
                        <LanguageSelector></LanguageSelector>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="mdi mdi-menu"></i>
                        </button>
                    </div>
                    

                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav ml-auto navbar-center" id="mySidenav">
                            <li className="nav-item">
                                <NavLink exact={true} activeClassName='active' to="/" className="nav-link">
                                    <Translation>{(t, { i18n }) => <>{t('i18n_home')}</>}</Translation>
                                </NavLink >
                            </li>
                            <li className="nav-item">
                                <NavLink to="/products" className="nav-link" data-target="#navToggleProducts" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                                    <Translation>{(t, { i18n }) => <>{t('i18n_products')}</>}</Translation>
                                </NavLink>
                                <ul className="dropdown-menu" id="navToggleProducts">
                                    <li className="nav-item">
                                        <NavLink exact={true} activeClassName='active' to="/products/data-ai-studio" className="nav-link">
                                            <Translation>{(t, { i18n }) => <>{t('i18n_ai_studio')}</>}</Translation>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink exact={true} activeClassName='active' to="/products/data-catalog" className="nav-link">
                                            <Translation>{(t, { i18n }) => <>{t('i18n_data_catalog')}</>}</Translation>
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                            {/* <li className="nav-item">
                                <NavLink to="/products" className="nav-link" data-target="#navToggleProducts" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                                    <Translation>{(t, { i18n }) => <>{t('i18n_products')}</>}</Translation>
                                </NavLink>
                                <ul className="dropdown-menu" id="navToggleProducts">
                                    <li className="nav-item">
                                        <NavLink exact={true} activeClassName='active' to="/products/data-pipeline" className="nav-link">
                                            <Translation>{(t, { i18n }) => <>{t('i18n_data_pipeline')}</>}</Translation>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink exact={true} activeClassName='active' to="/products/fraud-detection" className="nav-link">
                                            <Translation>{(t, { i18n }) => <>{t('i18n_ai_studio')}</>}</Translation>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink exact={true} activeClassName='active' to="/products/data-catalog" className="nav-link">
                                            <Translation>{(t, { i18n }) => <>{t('i18n_data_catalog')}</>}</Translation>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink exact={true} activeClassName='active' to="/products/data-lab" className="nav-link">
                                            <Translation>{(t, { i18n }) => <>{t('i18n_data_lab')}</>}</Translation>
                                        </NavLink>
                                    </li>
                                </ul>
                            </li> */}
                            <li className="nav-item">
                                <NavLink exact={true} activeClassName='active' to="/service" className="nav-link">
                                    <Translation>{(t, { i18n }) => <>{t('i18n_services')}</>}</Translation>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink exact={true} activeClassName='active' to="/about" className="nav-link">
                                    <Translation>{(t, { i18n }) => <>{t('i18n_about')}</>}</Translation>
                                </NavLink>
                            </li>
                            {/* <li className="nav-item">
                                <NavLink exact={true} activeClassName='active' to="/open-source" className="nav-link">
                                    <Translation>{(t, { i18n }) => <>{t('i18n_open_source')}</>}</Translation>
                                </NavLink>
                            </li> */}
                        </ul>
                    </div>
                    <div className="lang-only">
                        <LanguageSelector></LanguageSelector>
                    </div>
                </div>
            </nav>
        </React.Fragment>
    );
    
}

export default Navbar;