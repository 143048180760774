import React from 'react';
import { Translation } from 'react-i18next';

class ContactSection extends React.Component {
    
      render() {
        return (
            <React.Fragment>
                <section className="section" id="contacts">
                    <div className="sub-contacts contact-form">
                        <div className="contact-form-container">
                          <Translation>{ (t, { i18n }) => 
                            
                              <a className="btn btn-custom mr-4" href="https://calendly.com/alvaro-brito/primeiro-contato-fale-conosco" target="_blank" rel="noopener noreferrer">
                                <i className="mdi mdi-clock-outline"></i> {t('i18n_home_banner_contact_btn')}
                              </a>
                          
                          }</Translation>
                        </div>
                    </div>
                    <div className="sub-contacts contact-info">
                        <Translation>
                          {(t, { i18n }) => (
                            <>
                              <div className="memo-div">
                                <h4>{t('i18n_germany')}</h4>

                                <p><span className="font-weight-bold">{t('i18n_addrss')}:</span>  Gluckstraße 57</p>
                                <p><span className="font-weight-bold">{t('i18n_zipcode')}:</span> 22081</p>
                                <p><span className="font-weight-bold">{t('i18n_city')}:</span> Hamburg</p>
                                <p><span className="font-weight-bold">{t('i18n_state')}:</span> Hamburg</p>
                                <p><span className="font-weight-bold">{t('i18n_contact')}:</span> contact@dataseed.de</p>
                              </div>

                              <div className="memo-div">
                                <h4>{t('i18n_brazil')}</h4>

                                <p><span className="font-weight-bold">{t('i18n_addrss')}:</span> AV PAULISTA 2028 CONJ 111 E 4 VG 10 ANDAR</p>
                                <p><span className="font-weight-bold">{t('i18n_zipcode')}:</span> 01310-927</p>
                                <p><span className="font-weight-bold">{t('i18n_city')}:</span> São Paulo</p>
                                <p><span className="font-weight-bold">{t('i18n_state')}:</span> São Paulo</p>
                                <p><span className="font-weight-bold">{t('i18n_contact')}:</span> contact@dataseed.de</p>
                              </div>
                            </>
                          )}
                        </Translation>
                    </div>
                </section>
            </React.Fragment>
        )
    };
}
export default ContactSection;