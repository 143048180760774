import React from 'react';
import { Row,Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import OpenSourceDemoData from '../json/open-source-project-list.json';
import StarRating from './StarRating';
import { scrollToContact } from '../util.js';

import { useTranslation } from 'react-i18next';

const OpenSourceDetailBanner = (props) => {
    const { t } = useTranslation();

    const getProjectDetail = () => {
        let detail = OpenSourceDemoData.data.filter(o => o.projectId === parseInt(props.projectId));
        if (detail.length > 0) {
            return detail[0];
        }
        return {};
    }

    const getLng = () => {
        let currentLng = JSON.parse(localStorage.getItem('i18nLng'));
        return currentLng === null ? 'US' : currentLng;
    }

    return (
        <section className="section open-source-banner-bg" id="open-source">
            <img src="/images/open-source-banner-img.png" alt="" className="img-fluid mx-auto d-block products-banner-img" />
            <div className="div-center">
                <div className="div-desc-center">
                    <div className="container">
                        <Row className="align-items-center">
                            <Col lg="6" className="left-col open-source-detail-banner">
                                <div className="mt-40">
                                    <img className="project-icon" alt='' src={getProjectDetail().projectIcon} />
                                    <h1 className="text-white font-weight-normal big-text display-4 mb-0">
                                        {
                                            getLng() === 'US' ? getProjectDetail().projectName.en : getLng() === 'DE' ? getProjectDetail().projectName.de : getProjectDetail().projectName.pt
                                        }
                                    </h1>
                                    <p className="text-white-70 mt-2 mb-3 normal-text">
                                        {
                                            getLng() === 'US' ? getProjectDetail().introduction.en : getLng() === 'DE' ? getProjectDetail().introduction.de : getProjectDetail().introduction.pt
                                        }
                                    </p>
                                    <StarRating rate={getProjectDetail().rating}></StarRating>
                                    <div className="mt-5 btn-section">
                                        <Link to="#contact" className="btn btn-custom mr-4" onClick={scrollToContact}>
                                            {t('i18n_home_banner_btn')}
                                        </Link>
                                    </div>
                                </div>
                            </Col>

                            <Col lg="6" className="right-col">
                                <div className="mt-40 position-relative">
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="div-desc-center products-2nd-div">
                    <img src="/images/open-source-banner-img.png" alt="" className="img-fluid mx-auto d-block products-banner-img2" />
                </div>
            </div>
        </section>
    );
}
export default OpenSourceDetailBanner;