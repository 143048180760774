import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useTranslation  } from 'react-i18next';
import StarRating from './StarRating';
import OpenSourceDemoData from '../json/open-source-project-list.json';

const OpenSourceContentSection = () => {
    const { t } = useTranslation();
    const [state, setState] = useState({
        categoryTab: 'All',
        categories: [
            { id: 'All', label: 'i18n_all_projects' },
            { id: 'Data', label: 'i18n_data' },
            { id: 'Backend', label: 'i18n_backend' },
            { id: 'Frontend', label: 'i18n_frontend' },
            { id: 'Mobile', label: 'i18n_mobile' },
            { id: 'Infrastructure', label: 'i18n_infrastructure' },
            { id: 'Style guides', label: 'i18n_style_guides' },
            { id: 'Testing', label: 'i18n_testing' }
        ],
        testSourceData: OpenSourceDemoData.data
    });

    const onTabItemClicked = (id) => {
        setState({...state, categoryTab: id});
    }

    const getLng = () => {
      let currentLng = JSON.parse(localStorage.getItem('i18nLng'));
      return currentLng === null ? 'US' : currentLng;
    }

    return (
        <React.Fragment>
            <section className="section">
                <div className="container">
                    <Row>
                        <Col lg="12" md="12">
                            <div className="osc-tab">
                                {
                                    state.categories.map((category, index) => {
                                    return <div className={state.categoryTab === category.id ? 'osc-tab-item osc-active' : 'osc-tab-item'} key={index} onClick={() => onTabItemClicked(category.id)}>
                                                {t(category.label)}
                                           </div>
                                    })
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        {
                            state.testSourceData.filter(s => state.categoryTab !== 'All' ? s.category === state.categoryTab : true).map((o, index) => {
                                return <Col lg="4" md="6" key={index}>
                                    <div className="service-box source-item-box rounded mt-4 p-4">
                                        <div className="service-icon mb-3">
                                            <img src={o.projectIcon} alt='' />
                                        </div>
                                        <div className="services-desc">
                                            <div className="service-title mb-0 position-relative">
                                                <h5 className="font-weight-bold">
                                                    <Link to={"/open-source-detail/" + o.projectId} className="text-dark">
                                                        {getLng() === 'US' ? o.projectName.en : getLng() === 'DE' ? o.projectName.de : o.projectName.pt}
                                                    </Link>
                                                </h5>
                                            </div>
                                            <p className="service-intro mb-3">
                                                {getLng() === 'US' ? o.introduction.en : getLng() === 'DE' ? o.introduction.de : o.introduction.pt}
                                            </p>
                                            <StarRating rate={o.rating}></StarRating>
                                        </div>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                </div>
            </section>
        </React.Fragment>
    );
}
export default OpenSourceContentSection;