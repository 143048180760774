import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactFlagsSelect from 'react-flags-select'

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (code) => {
      let countryCode = 'en';

      switch (code) {
        case 'US':
          countryCode = 'en';
          break;
        case 'DE':
          countryCode = 'de';
          break;
        case 'PT':
          countryCode = 'pt';
          break;
        default:
          countryCode = 'en';
      }
      i18n.changeLanguage(countryCode);
      localStorage.setItem('i18nLng', JSON.stringify(code));
  }

  const getLng = () => {
    let currentLng = JSON.parse(localStorage.getItem('i18nLng'));
    return currentLng === null ? 'US' : currentLng;
  }

  return (
    <>
      <ReactFlagsSelect
        countries={["US", "DE", "PT"]}
        customLabels={{"US": "English", "DE": "Deutsch", "PT": "Português"}}
        defaultCountry={getLng()}
        showSelectedLabel={false}
        placeholder="Select Language"
        onSelect={changeLanguage} />
    </>
  )
}

export default LanguageSelector