import React from 'react';
import { Row,Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { scrollToContact } from '../util.js';

import { useTranslation } from 'react-i18next';

const OpenSourceBanner = () => {
    const { t } = useTranslation();
    return (
        <section className="section open-source-banner-bg" id="open-source">
            <img src="/images/open-source-banner-img.png" alt="" className="img-fluid mx-auto d-block products-banner-img" />
            <div className="div-center">
                <div className="div-desc-center">
                    <div className="container">
                        <Row className="align-items-center">
                            <Col lg="6" className="left-col">
                                <div className="mt-40">
                                    <h1 className="text-white font-weight-normal big-text display-4 mb-0">
                                        {t('i18n_open_source')}
                                    </h1>
                                    <p className="text-white-70 mt-4 mb-0 normal-text">
                                        {t('i18n_open_source_brief')}
                                    </p>
                                    <div className="mt-5 btn-section">
                                        <Link to="#contact" className="btn btn-custom mr-4" onClick={scrollToContact}>
                                            {t('i18n_home_banner_btn')}
                                        </Link>
                                    </div>
                                </div>
                            </Col>

                            <Col lg="6" className="right-col">
                                <div className="mt-40 position-relative">
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="div-desc-center products-2nd-div">
                    <img src="/images/open-source-banner-img.png" alt="" className="img-fluid mx-auto d-block products-banner-img2" />
                </div>
            </div>
        </section>
    );
}
export default OpenSourceBanner;