import React from 'react';
import { Row, Col } from 'reactstrap';
import { Translation  } from 'react-i18next';

class ServicesDescSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data_engineering_intros: [
                'i18n_home_data_engineering_intro1',
                'i18n_home_data_engineering_intro2',
                'i18n_home_data_engineering_intro3',
                'i18n_home_data_engineering_intro4',
                'i18n_home_data_engineering_intro5',
                'i18n_home_data_engineering_intro6',
                'i18n_home_data_engineering_intro7',
                'i18n_home_data_engineering_intro8',
                'i18n_home_data_engineering_intro9',
                'i18n_home_data_engineering_intro10',
                'i18n_home_data_engineering_intro11'
            ]
        }
    }

    render() {
        return (
            <React.Fragment>
                <section className="section">
                    <div className="container">
                        <Row>
                            <Col lg="12" md="12">
                                <div className="mb-4">
                                    <h3 className="big-text mb-1 font-weight-bold text-uppercase">
                                        <Translation>{ (t, { i18n }) => <>{t('i18n_devops')}</> }</Translation>
                                    </h3>
                                </div>
                                <p className="products-desc-text mb-3">
                                    <Translation>{ (t, { i18n }) => <>{t('i18n_devops_content1')}</> }</Translation>
                                </p>

                                <div className="mt-5 mb-4">
                                    <h3 className="big-text mb-1 font-weight-bold text-uppercase">
                                        <Translation>{ (t, { i18n }) => <>{t('i18n_home_data_pipeline')}</> }</Translation>
                                    </h3>
                                </div>
                                <p className="products-desc-text mb-3">
                                    <Translation>{ (t, { i18n }) => <>{t('i18n_home_data_pipeline_intro')}</> }</Translation>
                                </p>

                                <div className="mt-5 mb-4">
                                    <h3 className="big-text mb-1 font-weight-bold text-uppercase">
                                        <Translation>{ (t, { i18n }) => <>{t('i18n_home_data_lakes')}</> }</Translation>
                                    </h3>
                                </div>
                                <p className="products-desc-text mb-3">
                                    <Translation>{ (t, { i18n }) => <>{t('i18n_home_data_lakes_intro')}</> }</Translation>
                                </p>

                                <div className="mt-5 mb-4">
                                    <h3 className="big-text mb-1 font-weight-bold text-uppercase">
                                        <Translation>{ (t, { i18n }) => <>{t('i18n_home_data_analytics')}</> }</Translation>
                                    </h3>
                                </div>
                                <p className="products-desc-text mb-3">
                                    <Translation>{ (t, { i18n }) => <>{t('i18n_home_data_analytics_intro')}</> }</Translation>
                                </p>

                                <div className="mt-5 mb-4">
                                    <h3 className="big-text mb-1 font-weight-bold text-uppercase">
                                        <Translation>{ (t, { i18n }) => <>{t('i18n_home_data_engineering')}</> }</Translation>
                                    </h3>
                                </div>
                                {
                                    this.state.data_engineering_intros.map((o, index) => {
                                        return <p key={index} className="products-desc-text mb-3">
                                            <Translation>{ (t, { i18n }) => <>{t(o)}</> }</Translation>
                                        </p>
                                    })
                                }

                                <div className="mt-5 mb-4">
                                    <h3 className="big-text mb-1 font-weight-bold text-uppercase">
                                        <Translation>{ (t, { i18n }) => <>{t('i18n_solutions_by_demand')}</> }</Translation>
                                    </h3>
                                </div>
                                <p className="products-desc-text mb-3">
                                    <Translation>{ (t, { i18n }) => <>{t('i18n_solutions_by_demand_content1')}</> }</Translation>
                                </p>
                                

                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default ServicesDescSection;