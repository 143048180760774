import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Translation  } from 'react-i18next';

class ArticleSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ArticleList: [{
                "image": "/images/article-1.png",
                "title": "i18n_articles_title1",
                "content": "i18n_articles_intro1",
            }, {
                "image": "/images/article-2.png",
                "title": "i18n_articles_title2",
                "content": "i18n_articles_intro2",
            }, {
                "image": "/images/article-3.png",
                "title": "i18n_articles_title3",
                "content": "i18n_articles_intro3",
            }
            ]
        };
    }

    render() {
        return (
            <React.Fragment>
                <section className="section" id="articles">
                    <div className="container">
                        <Row>
                            <Col lg="12">
                                <div className="mb-2">
                                    <h3 className="big-text mb-1 font-weight-bold text-uppercase">
                                        <Translation>
                                        {
                                            (t, { i18n }) => <>{t('i18n_articles')}</>
                                        }
                                        </Translation>
                                    </h3>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            {this.state.ArticleList.map((article, index) => {
                                return <Col lg="4" md="6" key={index}>
                                    <div className="service-box rounded mt-4">
                                        <div className="article-img">
                                            <img src={article.image} alt='' />
                                        </div>
                                        <div className="services-desc p-4">
                                            <div className="service-title mb-1 position-relative">
                                                <h5 className="font-weight-normal">
                                                    <Link to="#" className="text-dark">
                                                        <Translation>{ (t, { i18n }) => <>{t(article.title)}</> }</Translation>
                                                    </Link>
                                                </h5>
                                            </div>
                                            <p className="service-intro mb-3">
                                                <Translation>{ (t, { i18n }) => <>{t(article.content)}</> }</Translation>
                                            </p>
                                            <p className="service-readmore">
                                                <Translation>{ (t, { i18n }) => <>{t('i18n_readmore')}</> }</Translation>
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            })}
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default ArticleSection;