import React from 'react';
import { Row,Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { scrollToContact } from '../util.js'

import { useTranslation } from 'react-i18next';

const HomeBanner = () => {
    const { t } = useTranslation();

    return (
                <section className="section home-banner-bg" id="home">
                    <img className="home-oval-1" src="/images/home-banner-oval-group.png" alt='' />
                    <div className="div-center">
                        <div className="div-desc-center">
                            <div className="container">
                                <Row className="align-items-center">
                                    <Col lg="6">
                                        <div className="mt-40">
                                            <h1 className="text-white font-weight-normal big-text display-4 mb-0">
                                                {t('i18n_home_banner_title')}
                                            </h1>
                                            <p className="text-white-70 mt-4 mb-0 normal-text">
                                                {t('i18n_home_banner_intro1')}
                                            </p>
                                            <p className="text-white-70 mt-4 mb-0 normal-text">
                                                {t('i18n_home_banner_intro2')}
                                            </p>
                                            <div className="mt-5 btn-section">
                                                <Link to="#contact" className="btn btn-custom mr-4" onClick={scrollToContact}>
                                                    {t('i18n_home_banner_btn')}
                                                </Link>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col lg="6">
                                        <div className="mt-40 position-relative">
                                            <img src="/images/home-banner-img1.png" alt="" className="img-fluid mx-auto d-block home-banner-img" />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </section>
    );
}
export default HomeBanner;