import React from 'react';
import ReactDOM from 'react-dom';
import Home from './pages/Home';
import DataPipeline from './pages/DataPipeline';
import AiStudio from './pages/AiStudio';
import DataCatalog from './pages/DataCatalog';
import DataLab from './pages/DataLab';
import OpenSource from './pages/OpenSource';
import OpenSourceDetail from './pages/OpenSourceDetail';
import About from './pages/About';
import Service from './pages/Service';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import './i18n';

import 'react-flags-select/css/react-flags-select.css'

class Root extends React.Component {

    render() {
        return(
            <BrowserRouter basename={'/'} >
              <Switch>
                <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home}/>
                <Route path={`${process.env.PUBLIC_URL}/about`} component={About}/>
                <Route path={`${process.env.PUBLIC_URL}/service`} component={Service}/>
                <Route path={`${process.env.PUBLIC_URL}/products/data-pipeline`} component={DataPipeline}/>
                <Route path={`${process.env.PUBLIC_URL}/products/data-ai-studio`} component={AiStudio}/>
                <Route path={`${process.env.PUBLIC_URL}/products/data-catalog`} component={DataCatalog}/>
                <Route path={`${process.env.PUBLIC_URL}/products/data-lab`} component={DataLab}/>
                <Route path={`${process.env.PUBLIC_URL}/open-source`} component={OpenSource}/>
                <Route path={`${process.env.PUBLIC_URL}/open-source-detail/:projectId`} component={OpenSourceDetail}/>
              </Switch>
          </BrowserRouter>
        );
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.unregister();
