import React, { Suspense } from 'react';
import Preloader from '../components/Preloader';
import Navbar from '../components/Navbar';
import AboutBanner from '../components/AboutBanner';
import AboutDescSection from '../components/AboutDescSection';
import ClientSection from '../components/ClientSection';
import ContactSection from '../components/ContactSection';
import Footer from '../components/Footer';
import FooterAlt from '../components/FooterAlt';

class About extends React.Component {

  componentDidMount() {
    document.getElementById("main_navbar").classList.add("navbar-light");
    window.scrollTo(0, 0);
  }

  render() {

    return (
      <React.Fragment>
        <Suspense fallback={(<div>Loading</div>)}>
          {/* preloader */}
          <Preloader />

          {/* Navigation Menu */}
          <Navbar />

          {/* AboutBanner Menu */}
          <AboutBanner />

          {/* AboutDescSection Menu */}
          <AboutDescSection />

          {/* ClientSection Menu */}
          <ClientSection />

          {/* ContactSection Menu */}
          <ContactSection subject='Dataseed About' />

          {/* Footer Menu */}
          <Footer />

          {/* FooterAlt Menu */}
          <FooterAlt />
        </Suspense>
      </React.Fragment>

    );
  }
}

export default About;