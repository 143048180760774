import React from 'react';
import { Row, Col } from 'reactstrap';
import { Translation  } from 'react-i18next';

class ClientSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ClientList: [
                {
                    src: '/images/clients/client1.svg',
                    link: 'https://kianava.de/'
                },
                {
                    src: '/images/clients/client2.png',
                    link: 'https://youpalgroup.com/'
                },
                {
                    src: '/images/clients/client3.svg',
                    link: 'https://www.publicissapient.com/'
                },
                {
                    src: '/images/clients/client4.svg',
                    link: 'https://mvpmatch.co/'
                },
            ]
        };
    }

    render() {
        return (
            <React.Fragment>
                <section className="section" id="clients">
                    <div className="container">
                        <Row>
                            <Col lg="12">
                                <div className="mb-2">
                                    <h3 className="big-text mb-1 font-weight-bold text-uppercase">
                                        <Translation>
                                        {
                                            (t, { i18n }) => <>{t('i18n_clients')}</>
                                        }
                                        </Translation>
                                    </h3>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg="12" md="12" className="client-row space-between">
                                {this.state.ClientList.map((client, index) => {
                                    const width = index === 1 ? '35%' : '20%'

                                    return (
                                        <div style={{width: index === 2 ? '15%' : width }} key={index}>
                                            <a href={client.link} target="__blank" rel="noopener">
                                                <img className='w-100' alt='' src={client.src} />
                                            </a>
                                        </div>
                                    )
                                })}
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default ClientSection;