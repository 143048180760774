import React, { Suspense } from 'react';
import Preloader from '../components/Preloader';
import Navbar from '../components/Navbar';
import OpenSourceBanner from '../components/OpenSourceBanner';
import OpenSourceContentSection from '../components/OpenSourceContentSection';
import ContactSection from '../components/ContactSection';
import Footer from '../components/Footer';
import FooterAlt from '../components/FooterAlt';

class OpenSource extends React.Component {

  componentDidMount() {
    document.getElementById("main_navbar").classList.add("navbar-light");
    window.scrollTo(0, 0);
  }

  render() {

    return (
      <React.Fragment>
        <Suspense fallback={(<div>Loading</div>)}>
          {/* preloader */}
          <Preloader />

          {/* Navigation Menu */}
          <Navbar />

          {/* OpenSourceBanner Menu */}
          <OpenSourceBanner />

          {/* OpenSourceContentSection Menu */}
          <OpenSourceContentSection />

          {/* ContactSection Menu */}
          <ContactSection subject='Dataseed Open Source' />

          {/* Footer Menu */}
          <Footer />

          {/* FooterAlt Menu */}
          <FooterAlt />
        </Suspense>
      </React.Fragment>

    );
  }
}

export default OpenSource;