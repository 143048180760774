import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useTranslation  } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <section className="footer-bg">
                <div className="container">
                    <Row>
                        <Col lg="4">
                            <div className="mb-5">
                                <img className="footer-logo" src="/images/dataseed-black-logo.png" alt='' />
                            </div>
                        </Col>

                        <Col lg="8">
                            <Row>
                                <Col lg="6" >
                                    <div className="mb-5">
                                        <p className="text-uppercase text-dark footer-title mb-4">{t('i18n_sitemap')}</p>
                                        <ul className="list-unstyled footer-sub-menu">
                                            <li className="f-14"><Link to="/" className="text-muted">{t('i18n_home')}</Link></li>
                                            {/* <li className="f-14"><Link to="/products/data-pipeline" className="text-muted">{t('i18n_products')}</Link></li> */}
                                            <li className="f-14"><Link to="/service" className="text-muted">{t('i18n_services')}</Link></li>
                                            <li className="f-14"><Link to="/about" className="text-muted">{t('i18n_about')}</Link></li>
                                            {/* <li className="f-14"><Link to="/open-source" className="text-muted">{t('i18n_open_source')}</Link></li> */}
                                        </ul>
                                    </div>
                                </Col>

                                <Col lg="6">
                                    <div className="mb-5 contact-box">
                                        <p className="text-uppercase text-dark footer-title mb-4">{t('18n_social_links')}</p>
                                        <ul className="follow-icon list-inline mt-32 mb-0">
                                            <li className="list-inline-item f-15">
                                                <a className="social-icon facebook-icon" href="https://instagram.com/dataseed.de/" target="_blank" rel="noopener noreferrer">
                                                    <i className="mdi mdi-facebook"></i>
                                                </a>
                                            </li>&nbsp;
                                            <li className="list-inline-item f-15">
                                                <a className="social-icon linkedin-icon" href="https://instagram.com/dataseed.de/" target="_blank" rel="noopener noreferrer">
                                                    <i className="mdi mdi-linkedin"></i>
                                                </a>
                                            </li>&nbsp;
                                            <li className="list-inline-item f-15">
                                                <a className="social-icon instagram-icon" href="https://instagram.com/dataseed.de/" target="_blank" rel="noopener noreferrer">
                                                    <i className="mdi mdi-instagram"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </section>
        </React.Fragment>
    );
}
export default Footer;