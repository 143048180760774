import React, { Suspense } from 'react';
import Preloader from '../components/Preloader';
import Navbar from '../components/Navbar';
import HomeBanner from '../components/HomeBanner';
import ServiceSection from '../components/ServiceSection';
import ArticleSection from '../components/ArticleSection';
import ClientSection from '../components/ClientSection';
import ContactSection from '../components/ContactSection';
import Footer from '../components/Footer';
import FooterAlt from '../components/FooterAlt';

class Home extends React.Component {

  componentDidMount() {
    document.getElementById("main_navbar").classList.add("navbar-light");
    window.scrollTo(0, 0);
  }

  render() {

    return (
      <React.Fragment>
        <Suspense fallback={(<div>Loading</div>)}>
          {/* preloader */}
          <Preloader />

          {/* Navigation Menu */}
          <Navbar />

          {/* HomeSection Menu */}
          <HomeBanner />

          {/* ServiceSection Menu */}
          <ServiceSection />

          {/* ArticleSection Menu */}
          <ArticleSection />

          {/* ClientSection Menu */}
          <ClientSection />

          {/* ContactSection Menu */}
          <ContactSection subject='First Contact'/>

          {/* Footer Menu */}
          <Footer />

          {/* FooterAlt Menu */}
          <FooterAlt />
        </Suspense>
      </React.Fragment>

    );
  }
}

export default Home;