import React from 'react';

const StarRating = (props) => {

    const getYValue = () => {
        let starData = [];
        for (let i = 0; i < Math.round(props.rate); i++) {
            starData.push(1);
        }
        return starData;
    }

    const getGValue = () => {
        let starData = [];
        for (let i = 0; i < 5 - Math.round(props.rate); i++) {
            starData.push(1);
        }
        return starData;
    }

    return (
        <div>
            {
                getYValue().map((o, index) => {
                    return <img key={'s' + index} alt='Voted Star' className="star-img" src="/images/yellow-star.png" />
                })
            }
            {
                getGValue().map((o, index) => {
                    return <img key={'t' + index} alt='Not Voted Star' className="star-img" src="/images/white-gray-star.png" />
                })
            }
        </div>
    );
}
export default StarRating;