import React, { Suspense } from 'react';
import Preloader from '../components/Preloader';
import Navbar from '../components/Navbar';
import OpenSourceDetailBanner from '../components/OpenSourceDetailBanner';
import OpenSourceDetailContentSection from '../components/OpenSourceDetailContentSection';
import ContactSection from '../components/ContactSection';
import Footer from '../components/Footer';
import FooterAlt from '../components/FooterAlt';

class OpenSourceDetail extends React.Component {

  componentDidMount() {
    document.getElementById("main_navbar").classList.add("navbar-light");
    window.scrollTo(0, 0);
  }

  render() {

    return (
      <React.Fragment>
        <Suspense fallback={(<div>Loading</div>)}>
          {/* preloader */}
          <Preloader />

          {/* Navigation Menu */}
          <Navbar />

          {/* OpenSourceDetailBanner Menu */}
          <OpenSourceDetailBanner projectId={this.props.match.params.projectId} />

          {/* OpenSourceDetailContentSection Menu */}
          <OpenSourceDetailContentSection projectId={this.props.match.params.projectId} />

          {/* ContactSection Menu */}
          <ContactSection subject='Dataseed Open Source Project - ' projectId={this.props.match.params.projectId} />

          {/* Footer Menu */}
          <Footer />

          {/* FooterAlt Menu */}
          <FooterAlt />
        </Suspense>
      </React.Fragment>

    );
  }
}

export default OpenSourceDetail;