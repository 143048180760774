import React, { Suspense } from 'react';
import Preloader from '../components/Preloader';
import Navbar from '../components/Navbar';
import AiStudioBanner from '../components/AiStudioBanner';
import AiStudioDescSection from '../components/AiStudioDescSection';
import ClientSection from '../components/ClientSection';
import ContactSection from '../components/ContactSection';
import Footer from '../components/Footer';
import FooterAlt from '../components/FooterAlt';

class AiStudio extends React.Component {

  componentDidMount() {
    document.getElementById("main_navbar").classList.add("navbar-light");
    window.scrollTo(0, 0);
  }

  render() {

    return (
      <React.Fragment>
        <Suspense fallback={(<div>Loading</div>)}>
          {/* preloader */}
          <Preloader />

          {/* Navigation Menu */}
          <Navbar />

          {/* AiStudioBanner Menu */}
          <AiStudioBanner />

          {/* AiStudioDesSection Menu */}
          <AiStudioDescSection />

          {/* ClientSection Menu */}
          <ClientSection />

          {/* ContactSection Menu */}
          <ContactSection subject='Dataseed Data AI Studio' />

          {/* Footer Menu */}
          <Footer />

          {/* FooterAlt Menu */}
          <FooterAlt />
        </Suspense>
      </React.Fragment>

    );
  }
}

export default AiStudio;